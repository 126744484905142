import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import type { SxProps } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { FC } from 'react';

import Theme from '../theme/theme';

export const DropdownItem: FC<MenuItemProps> = (props: MenuItemProps) => {
  const { palette } = Theme;

  return (
    <MenuItem
      {...props}
      sx={{
        color: `Grey900`,
        fontSize: '14px',
        backgroundColor: props.selected ? `${palette.Grey100} !important` : `transparent`,
        ...(props.sx ? { ...props.sx } : ''),
      }}
    />
  );
};

/**
 * Dropdown component
 * @param buttonLabel - label shown on the button
 * @param children - list of `DropdownItem` elements or other react nodes, which will be rendered as dropdown items
 * @param active - sets button's active state; false by default
 * @param closeOnClick - if set to false, prevents dropdown from closing on any item click; true by default
 * @param compact - compact version of the dropdown button; false by default
 * @param menuAutoWidth - forcing the dropdown's width to match its items' width ; false by default
 * @param sx - custom style that applies to dropdown button
 * @param labelSx - custom style that applies to the button label
 * @constructor
 */
const Dropdown: FC<{
  buttonLabel: string;
  typographyVariant?: Variant;
  menuPadding?: string;
  active?: boolean;
  closeOnClick?: boolean;
  menuAutoWidth?: boolean;
  compact?: boolean;
  sx?: SxProps;
  labelSx?: SxProps;
  dataTestId?: string;
  leftIcon?: JSX.Element;
  children: React.ReactNode;
}> = ({
  buttonLabel,
  typographyVariant,
  menuPadding,
  children,
  active,
  closeOnClick = true,
  compact,
  menuAutoWidth,
  sx,
  labelSx,
  leftIcon,
  dataTestId,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { spacing, typography } = Theme;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handlePanelClick = () => {
    if (closeOnClick) handleClose();
  };

  return (
    <>
      <Button
        id={`dropdown-btn-${buttonLabel}`}
        aria-controls={open ? `dropdown-panel-${buttonLabel}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={active ? 'active' : ''}
        startIcon={leftIcon}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        sx={{
          height: compact ? spacing(4) : spacing(7),
          color: `Grey900`,
          borderRadius: '8px',
          backgroundColor: `Grey100`,
          fontWeight: 'normal',
          p: '0 12px',
          '&:hover': {
            backgroundColor: `Grey300`,
          },
          '&.active': {
            border: '1px solid',
            borderColor: `Grey900`,
          },
          '.MuiButton-endIcon': {
            marginLeft: '0 !important',
          },
          ...sx,
        }}
        {...rest}
        data-testid={dataTestId}
      >
        <Typography
          variant={typographyVariant ?? 'body2'}
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            color: 'Grey900',
            ...labelSx,
          }}
        >
          {buttonLabel}
        </Typography>
      </Button>
      <Menu
        onClick={handlePanelClick}
        id={`dropdown-panel-${buttonLabel}`}
        MenuListProps={{
          'aria-labelledby': `dropdown-btn-${buttonLabel}`,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={4}
        data-testid={`${dataTestId}-overlay`}
        sx={{
          '.MuiPaper-root': {
            mt: 1,
            borderRadius: '8px',
            minWidth: menuAutoWidth ? 'auto' : '280px',
            ...(menuPadding ? { padding: menuPadding } : {}),
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default Dropdown;
