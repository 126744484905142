import { Distance, Mass, MassUnit } from '@lune-climate/lune'
import { countriesList } from '@lune-fe/lune-ui-lib'
import { Big } from 'big.js'
import getUserLocale from 'get-user-locale'
import { None, Option, Some } from 'ts-results-es'

export function optionFromNullable<T>(value: T | null): Option<T> {
    if (value === null) {
        return None
    }
    return Some(value)
}

export const formatNumbers = (number: number | string, decimals?: number) => {
    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    if (decimals) {
        number = parseFloat(number.toString()).toFixed(decimals)
    }

    try {
        numberFormat = new Intl.NumberFormat(userLocale)
    } catch (e: any) {
        // ^ No type safety in this block, we can in principle receive anything and assume things.

        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat.
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale)
    }

    try {
        return numberFormat.format(Big(number).toNumber())
    } catch (error) {
        console.error('Error formatting number:', error)
    }

    return number
}

export const convertToTonne = (mass: Mass) => {
    return mass.unit === 't'
        ? Big(mass.amount)
        : mass.unit === 'kg'
          ? Big(mass.amount).div(1000)
          : Big(mass.amount).div(1000000)
}

/**
 * Accepts a three-letter country code and returns the full country name from a predefined list of countries.
 * Optionally, you can pass a callback function to be called if the country code is not found.
 */
export const getCountryNameByThreeLetterCode = (
    code: string,
    onFail?: () => void,
): string | undefined => {
    const country = countriesList.find((c) => c[2] === code)?.[0]

    if (!country && onFail) {
        onFail()
    }

    return country
}

/**
 * Takes a stringified number and converts it to a standardized format in terms of grams (G), kilograms (KG), or tons (T).
 *
 * @param {string} amount - The stringified number to be formatted.
 *
 * If the number is less than 1 (but not 0 or empty), it will be converted to kilograms if the number is greater than or equal to 0.001, or grams if it's less than 0.001.
 * If the number is greater than or equal to 1, it will be converted to tons.
 * If the input is 0, empty, or null, it will return 0 grams.
 *
 * @returns {Object} An object containing the formatted number and the mass unit. The object has the following properties:
 * - amountAsNumber: The formatted number
 * - amountUnit: The unit of mass, which can be 'G', 'KG', or 'T'
 */
export const getFormattedAmount = (
    amount: string,
): {
    amountAsNumber: number
    amountUnit: MassUnit
} => {
    const amountAsNumber = Big(amount || 0)
    if (amountAsNumber.lt(1)) {
        const amountInKilos = amountAsNumber.mul(1000).toNumber()
        if (amountInKilos < 1) {
            return {
                amountAsNumber: Big(amountInKilos).mul(1000).toNumber(),
                amountUnit: MassUnit.G,
            }
        } else {
            return {
                amountAsNumber: amountAsNumber.mul(1000).toNumber(),
                amountUnit: MassUnit.KG,
            }
        }
    } else {
        return {
            amountAsNumber: amountAsNumber.toNumber(),
            amountUnit: MassUnit.T,
        }
    }
}

export function getDistanceToKM(distance: Distance): Big {
    switch (distance.unit) {
        case Distance.unit.KM:
            return Big(distance.amount)
        case Distance.unit.NM:
            return Big(distance.amount).mul(1.852)
        case Distance.unit.MI:
            return Big(distance.amount).mul(1.60934)
    }
}

export const toTitleCase = (s: string) => {
    return s[0].toUpperCase() + s.slice(1)
}

export const formatToCurrency = (number: number | string, currency: string) => {
    if (!currency) {
        return number.toString()
    }

    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol',
        })
    } catch (e: any) {
        // ^ No type safety in this block, we can in principle receive anything and assume things.

        console.warn(
            `${e.name} was caught while constructing Intl.NumberFormat.
                      Retrying with omitted currencyDisplay: 'narrowSymbol' (known not to be supported on some Safari versions...`,
        )
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
        })
    }

    return numberFormat.format(Big(number).toNumber())
}
