import { toTitleCase } from '@lune-fe/lune-components-lib'
import { Box } from '@mui/material'
import { useMemo } from 'react'

/**
 * Category name: [r,g,b] colour
 */
export const categoryMetadata = new Map<string, [number, number, number]>([
    ['accommodation', [255, 225, 117]],
    ['advertising services', [183, 126, 255]],
    ['agriculture', [255, 185, 103]],
    ['air transportation', [0, 153, 241]],
    ['beverages: alcoholic', [229, 126, 255]],
    ['beverages: non-alcoholic', [105, 223, 201]],
    ['catering services', [255, 225, 117]],
    ['clothing', [253, 240, 125]],
    ['cloud computing', [105, 223, 201]],
    ['construction', [255, 185, 103]],
    ['construction materials', [255, 185, 103]],
    ['consumer goods rental', [255, 139, 216]],
    ['cultural services', [229, 126, 255]],
    ['dairy products', [255, 225, 117]],
    ['domestic services', [255, 139, 216]],
    ['educational services', [120, 125, 248]],
    ['financial services', [105, 223, 201]],
    ['fish and seafood', [0, 153, 241]],
    ['fishing activities', [0, 153, 241]],
    ['footwear', [255, 185, 103]],
    ['forestry activities', [196, 253, 158]],
    ['grains and grain products', [255, 225, 117]],
    ['information and communication services', [183, 126, 255]],
    ['infrastructure', [189, 189, 189]],
    ['land use change', [255, 119, 89]],
    ['legal services', [120, 125, 248]],
    ['livestock', [255, 119, 89]],
    ['meat, poultry and eggs', [255, 119, 89]],
    ['medical services', [255, 67, 67]],
    ['mining activities', [189, 189, 189]],
    ['nuts and seeds', [255, 225, 117]],
    ['oils and fats', [253, 240, 125]],
    ['plants', [196, 235, 158]],
    ['publishing services', [183, 126, 255]],
    ['rail transportation', [255, 185, 103]],
    ['recreational services', [229, 126, 255]],
    ['restaurants', [255, 225, 117]],
    ['road transportation', [189, 189, 189]],
    ['sports products', [255, 225, 117]],
    ['storage', [255, 225, 117]],
    ['textiles', [229, 126, 255]],
    ['vehicle maintenance and services', [59, 201, 240]],
    ['water transportation', [0, 153, 241]],
    ['ready-made food and other food products', [255, 119, 89]],
    ['sugar, confectionery and desserts', [253, 240, 125]],
    ['metals', [59, 201, 240]],
    ['metal manufacturing', [59, 201, 240]],
    ['natural materials', [255, 185, 103]],
    ['wood materials', [255, 119, 89]],
    ['food processing', [255, 119, 89]],
    ['ceramic products', [59, 201, 240]],
    ['chemicals and chemical products', [105, 223, 201]],
    ['electrical equipment', [253, 240, 125]],
    ['electronic products', [105, 223, 201]],
    ['furniture', [255, 119, 89]],
    ['home appliances', [255, 225, 117]],
    ['machinery', [189, 189, 189]],
    ['medical products', [255, 67, 67]],
    ['office supplies', [255, 185, 103]],
    ['paint', [0, 153, 241]],
    ['paper products', [255, 185, 103]],
    ['personal care products', [255, 225, 117]],
    ['pharmaceutical products', [244, 67, 67]],
    ['tobacco products', [255, 187, 103]],
    ['vehicle parts', [59, 201, 240]],
    ['fuel', [0, 153, 241]],
    ['heat and steam', [255, 185, 103]],
    ['utilities', [253, 240, 125]],
    ['refrigerants and fugitive gases', [105, 223, 201]],
    ['commercial and industrial waste', [189, 189, 189]],
    ['construction waste', [189, 189, 189]],
    ['household waste', [189, 189, 189]],
    ['waste management', [189, 189, 189]],
    ['water', [0, 153, 241]],
    ['glass', [59, 201, 240]],
    ['plastics', [59, 201, 240]],
    ['packaging', [255, 185, 103]],
    ['business services', [120, 125, 248]],
    ['government services', [120, 125, 248]],
    ['gardening services', [196, 235, 258]],
    ['fruits and vegetables', [196, 235, 158]],
])

const CategoryTile = ({ name }: { name: string | null }) => {
    const categoryRgb = useMemo(() => {
        const grey = [189, 189, 189]
        if (name) {
            return categoryMetadata.get(name) ?? grey
        }
        return grey
    }, [name])

    if (!name) {
        return <>—</>
    }

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                backgroundColor: `rgba(${categoryRgb.join(',')}, 0.3)`,
                borderRadius: '14px',
                padding: '4px 8px',
            }}
        >
            <Box
                sx={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: `rgba(${categoryRgb.join(',')})`,
                    borderRadius: '4px',
                }}
            ></Box>
            <Box
                sx={{
                    display: 'block',
                    maxWidth: { xs: '190px', xl: '250px' },
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {toTitleCase(name)}
            </Box>
        </Box>
    )
}

export default CategoryTile
